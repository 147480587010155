import { AppService } from './../../shared/services/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FirebaseService } from './../../shared/services/firebase.service';
import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ViewChildren,
  ElementRef,
} from '@angular/core';
import { TranscriptionService } from 'src/app/shared/services/transcriptions.service';
import { TranscriptionModalComponent } from './transcription-modal/transcription-modal.component';
import {
  AcumenDictationData,
  AcumenDictationFirebaseData,
  Assistant,
  AudioDictationTableData,
  DictationData,
  GET_ALL_DICTATIONS_RESP,
  Provider,
  ServerSidePages,
} from 'src/app/shared/models/';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterModalComponent } from 'src/app/shared/components/filter-modal/filter-modal.component';
import { AudioDictationService } from 'src/app/shared/services/audio-dictation.service';
import { AcumenFirebaseService } from 'src/app/shared/services/acumen-firebase.service';
// import { DataSnapshot } from '@angular/fire/database/interfaces';
import { PaginationTableHeaders } from 'src/app/shared/models/generic';
import { ToastrService } from 'ngx-toastr';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import * as moment from 'moment';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import * as Editor from '../../../assets/textEditor/build/ckeditor';
import { AudioTypeModalComponent } from './audio-type-modal/audio-type-modal.component';
@Component({
  selector: 'app-audio-dictation',
  templateUrl: './audio-dictation.component.html',
  styleUrls: ['./audio-dictation.component.scss'],
})
export class AudioDictationComponent implements OnInit {
  // transcriptionTableData: any[];
  audioTableHeaders: PaginationTableHeaders[] = [
    { title: 'no', value: 'number', sort: false, orderBy: '' },
    {
      title: 'dictation_number',
      value: 'dictation_number',
      sort: false,
      orderBy: '',
    },
    {
      title: 'name',
      value: 'patient_name',
      sort: true,
      orderBy: '',
    },
    { title: 'MRN', value: 'MRN', sort: false, orderBy: '' },
    { title: 'created_at', value: 'created_at', sort: true, orderBy: '' },
    {
      title: 'provider',
      value: 'provider_name',
      sort: true,
      orderBy: '',
    },
  ];
  MissingAudioTableHeaders: PaginationTableHeaders[] = [
    { title: 'no', value: 'number', sort: false, orderBy: '' },
    {
      title: 'dictation_number',
      value: 'provider_name',
      sort: false,
      orderBy: '',
    },
    {
      title: 'name',
      value: 'patient_name',
      sort: false,
      orderBy: '',
    },
    { title: 'MRN', value: 'MRN', sort: false, orderBy: '' },
    {
      title: 'provider',
      value: 'provider_name',
      sort: false,
      orderBy: '',
    },
    {
      title: 'status',
      value: 'status',
      sort: false,
      orderBy: '',
    },
    {
      title: 'created_at',
      value: 'created_at',
      sort: true,
      orderBy: '',
    },
  ];
  acumenTableHeadersData = ['No', 'Name', 'MRN', 'Date_Of_Service', 'Provider'];
  acumenDictationTableData: AcumenDictationData[] = [];
  audioDictationTableData: AudioDictationTableData[];
  MissingAudioDictationTableData: AudioDictationTableData[];
  audioDictationCompleteTableData: DictationData[];
  audioDictationFilteredTableData: DictationData[];
  assistantInfo: Assistant = null;
  tablePages: ServerSidePages;
  missingAudioTablePages: ServerSidePages;
  pageSize: number = 6;
  currentPage = 1;
  style: string = 'flex';
  searchKeyword: string = '';
  searchCriteria: string = 'patient_name';
  selectedProvider: { description: string; id: string };
  selectedAudio: { url: string; id: string } = { url: '', id: '' }; // Dictation Selected for audio Playback
  msaapPlaylist: { title: string; link: string } = { title: '', link: '' };
  userRole: string;

  transcription: any[] = [];
  @ViewChild('attachmentSwitch', { static: false })
  attachmentSwitch: MatSlideToggle;
  @ViewChild('audioPlayer', { static: false })
  audioPlayer: any;
  @ViewChild('transcriptionDiv') transcriptionDiv: ElementRef;
  recordFound: boolean = false;
  textToShow = [];
  currentWord: any;
  currentIndex: any;
  currentScrollPosition: any;
  currentTime: any;
  public editor = Editor;
  transcriptionText: string = '';
  responseData: any = null;
  transcriptionData: any = null;

  @HostListener('document:keydown.shift.arrowright') next() {
    this.nextPage();
  }
  @HostListener('document:keydown.shift.control.arrowright')
  end() {
    this.lastPage();
  }
  @HostListener('document:keydown.shift.arrowleft')
  prev() {
    this.prevPage();
  }
  @HostListener('document:keydown.shift.control.arrowleft')
  start() {
    this.firstPage();
    this.missingAudioFirstPage();
  }

  constructor(
    private transcriptionService: TranscriptionService,
    private firebaseService: FirebaseService,
    private router: Router,
    private authService: AuthService,
    private appService: AppService,
    private toastr: ToastrService,
    private audioDictationService: AudioDictationService,
    private spinnerService: NgxSpinnerService,
    private acumenFirebaseService: AcumenFirebaseService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init(): Promise<void> {
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    this.userRole = await this.authService.getUserRole();

    switch (this.userRole) {
      case 'location_admin': {
        this.router.navigate([`/dashboard/users`]);
        break;
      }
      case 'location_user': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_benefits': {
        this.router.navigate([`/dashboard/orders`]);
        break;
      }
      case 'practice_scribe': {
        this.router.navigate([`/dashboard/video-dictation`]);
        break;
      }
    }
    this.assistantInfo = await this.firebaseService.getAssistant(
      loggedInUser.uid,
      loggedInUser.displayName
    );

    this.getAudioDictations();
    // this.acumenGetAudioDictations();
    this.getMissingAudioDictations();
  }

  getAudioDictations(
    page: number = 1,
    limit: number = this.pageSize,
    filterByProvider: string = '',
    orderBy: any = {}
  ): void {
    this.spinnerService.show();

    this.audioDictationService
      .getAudioDictations(
        this.assistantInfo.client_id,
        this.assistantInfo.practiceId,
        page,
        limit,
        orderBy,
        this.searchKeyword,
        this.searchCriteria,
        filterByProvider,
        this.attachmentSwitch
          ? this.attachmentSwitch.checked
            ? true
            : false
          : null
      )
      .then((resp: GET_ALL_DICTATIONS_RESP) => {
        this.tablePages = {
          currentPage: resp.data.currentPage,
          totalPages: Math.trunc(
            (resp.data.total + resp.data.limit - 1) / resp.data.limit
          ),
          lastPage: Math.trunc(
            (resp.data.total + resp.data.limit - 1) / resp.data.limit
          ),
          nextPage: resp.data.nextPage,
          prevPage: resp.data.previousPage,
          firstPage: 1,
          total: resp.data.total,
        };
        this.audioDictationTableData = resp.data.data.map(
          (audio: DictationData, index: number) => ({
            no: index + 1,
            id: audio.id,
            file_path: audio.file_url,
            name: audio.patient.patient_name,
            patient_id: audio.patient.patient_id,
            MRN: audio.patient.med_rec_nbr,
            provider: audio.provider.description,
            provider_id: audio.provider.provider_id,
            patient_row_id: audio.patient.patient_row_id,
            created_at:
              (audio.created_at &&
                moment(audio.created_at).format('MM/DD/YYYY hh:mm a')) ||
              '',
            dictation_number: audio.dictation_number,
            transcription_text: audio.transcription_text,
            ai_transcriptions: audio.ai_transcriptions[0],
          })
        );
      })
      .catch((err) => {
        this.toastr.error('Something went wrong. Please try again');
      })
      .finally(() => this.spinnerService.hide());
  }

  downloadTranscription = (data: any) => {
    const fileName = data.file_path;
    const parts = fileName.split('/');
    const fileNameWithExtension = parts[parts.length - 1];
    const lastDotIndex = fileNameWithExtension.lastIndexOf('.');
    const transcriptionName =
      fileNameWithExtension.substring(0, lastDotIndex) + '-transcription';
    this.spinner.show();

    this.transcriptionService
      .checkTranscriptionFileExistence(
        'vytlaudiobucket',
        `transcribed/${transcriptionName}.json`
      )
      .then((exists: boolean) => {
        if (exists) {
          this.transcriptionService
            .downloadFile(
              'vytlaudiobucket',
              `transcribed/${transcriptionName}.json`
            )
            .then((fileContent: ArrayBuffer) => {
              const text = new TextDecoder('utf-8').decode(
                new Uint8Array(fileContent)
              );
              const jsonData = JSON.parse(text);
              const transcript = jsonData.results.transcripts[0].transcript;
              this.saveTranscriptionFile(transcript, transcriptionName);
            })
            .catch((error: any) => {
              console.error('Error downloading transcription file:', error);
            })
            .finally(() => this.spinner.hide());
        } else {
          this.toastr.info(
            'Transcription file not found. The transcription process might not be completed yet, please try again later.'
          );
          this.spinner.hide();
        }
      })
      .catch((error: any) => {
        this.spinner.hide();
        console.error('Error checking transcription file existence:', error);
      });
  };
  saveTranscriptionFile(transcript: string, fileName: string): void {
    const blob = new Blob([transcript], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${fileName}.txt`;
    link.click();
    window.URL.revokeObjectURL(url);
  }

  lastPage = (): void => {
    let order,
      provider = '';
    this.audioTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.selectedProvider && this.selectedProvider.id) {
      provider = this.selectedProvider.id;
    }
    if (this.tablePages.nextPage)
      this.getAudioDictations(
        this.tablePages.lastPage,
        this.pageSize,
        provider,
        order
      );
  };

  nextPage = (): void => {
    let order,
      provider = '';
    this.audioTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.selectedProvider && this.selectedProvider.id) {
      provider = this.selectedProvider.id;
    }

    if (this.tablePages.nextPage)
      this.getAudioDictations(
        this.tablePages.nextPage,
        this.pageSize,
        provider,
        order
      );
  };

  prevPage = (): void => {
    let order,
      provider = '';
    this.audioTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.selectedProvider && this.selectedProvider.id) {
      provider = this.selectedProvider.id;
    }
    if (this.tablePages.prevPage)
      this.getAudioDictations(
        this.tablePages.prevPage,
        this.pageSize,
        provider,
        order
      );
  };

  firstPage = (): void => {
    let order,
      provider = '';
    this.audioTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.selectedProvider && this.selectedProvider.id) {
      provider = this.selectedProvider.id;
    }
    if (this.tablePages.prevPage)
      this.getAudioDictations(
        this.tablePages.firstPage,
        this.pageSize,
        provider,
        order
      );
  };

  customPage = (page: string): void => {
    const pageNumber = parseInt(page);
    if (pageNumber === this.tablePages.currentPage) {
      this.toastr.info('Page already selected.');
      return;
    } else if (
      typeof pageNumber === 'number' &&
      pageNumber <= this.tablePages.totalPages &&
      0 < pageNumber
    ) {
      let order,
        provider = '';
      this.audioTableHeaders.forEach((header) => {
        if (header.orderBy) {
          order = {
            column_name: header.value,
            sort: header.orderBy,
          };
        }
      });

      if (this.selectedProvider && this.selectedProvider.id) {
        provider = this.selectedProvider.id;
      }
      this.getAudioDictations(pageNumber, this.pageSize, provider, order);
    } else
      this.toastr.error(
        'Please enter a page number between the range of ' +
          this.tablePages.firstPage +
          ' to ' +
          this.tablePages.lastPage
      );
  };

  orderBy = (column_name: string): void => {
    const index = this.audioTableHeaders.findIndex(
      (header) => header.value === column_name
    );
    let provider = '';

    this.audioTableHeaders.forEach((header) => {
      if (header.value != column_name) header.orderBy = '';
    });

    if (this.audioTableHeaders[index].orderBy === 'DESC')
      this.audioTableHeaders[index].orderBy = 'ASC';
    else this.audioTableHeaders[index].orderBy = 'DESC';

    if (this.selectedProvider && this.selectedProvider.id) {
      provider = this.selectedProvider.id;
    }

    const order = {
      column_name,
      sort: this.audioTableHeaders[index].orderBy,
    };

    this.getAudioDictations(
      this.tablePages.currentPage,
      this.pageSize,
      provider,
      order
    );
  };

  deleteAudioDictation = (dictation: AudioDictationTableData): void => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        this.audioDictationService
          .deleteAudioDictation(dictation.id)
          .then(() => {
            this.spinnerService.hide();
            this.init();
            Swal.fire({
              icon: 'success',
              title: 'Deleted!',
              text: 'The Dictation was deleted Successfully.',
              showConfirmButton: false,
              timer: 2000,
            });
          })
          .catch((err) => {
            this.spinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Unsuccessful!',
              text: 'The Dictation deletion process has failed.',
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    });
  };
  downloadAiTranscriptionFunction = (dictation: any): void => {
    try {
      this.responseData = JSON.parse(dictation.ai_transcriptions.doctor_notes);
      this.transcriptionData = dictation.ai_transcriptions?.transcription;
      const orderAddRef = this.modalService.open(TranscriptionModalComponent, {
        ariaLabelledBy: 'modal-basic-title',
        animation: true,
        centered: true,
        size: 'xl',
      });
      orderAddRef.componentInstance.responseData = this.responseData;
      orderAddRef.componentInstance.transcriptionData = this.transcriptionData;
    } catch (error) {
      console.error('Error parsing JSON:', error);
      this.toastr.error('Failed to parse response data');
    }
  };

  downloadAudioDictation = (dictation: AudioDictationTableData): void => {
    this.spinner.show();
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    const url = dictation.file_path;
    xhr.onload = () => {
      var blob = new Blob([xhr.response], { type: 'audio/mp3' });
      var url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download =
        `${dictation.name}${'-'}${dictation['dictation_number']}` || 'download';
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url);
          a.removeEventListener('click', clickHandler);
        }, 150);
      };
      a.addEventListener('click', clickHandler, false);
      a.click();
      this.spinner.hide();
    };
    xhr.open('GET', url);
    xhr.send();
  };

  onWordClick = (event) => {
    this.audioPlayer.nativeElement.currentTime = event.start_time;
  };

  scrollToCurrentWord(currentWord: any, currentIndex: any) {
    if (this.transcriptionDiv && this.transcriptionDiv.nativeElement) {
      const element = this.transcriptionDiv.nativeElement as HTMLElement;
      const wordElements = element.querySelectorAll('span');
      if (currentIndex !== -1) {
        const scrollPosition = wordElements[currentIndex].offsetTop;
        if (this.currentScrollPosition === scrollPosition) {
          return;
        } else {
          this.currentScrollPosition = scrollPosition;
          element.scrollTo({
            top: scrollPosition - 212,
            behavior: 'smooth',
          });
        }
      }
    }
  }

  updateTranscription = (event: any) => {
    this.currentTime = this.audioPlayer.nativeElement.currentTime;
    this.currentWord = this.transcription.find(
      (word) =>
        this.currentTime >= word.start_time && this.currentTime <= word.end_time
    );
    this.currentIndex = this.transcription.indexOf(this.currentWord);
    if (this.currentWord?.alternatives[0].content) {
      const currentWord = this.currentWord?.alternatives[0].content;
      const currentIndex = this.currentIndex;
      this.scrollToCurrentWord(currentWord, currentIndex);
    }

    // const array = [...this.transcription];
    // const chunkSize = 25;
    // const arrayOfChunks = this.chunkArray(array, chunkSize);

    // const originalIndex = currentIndex;

    // const chunkIndex = Math.floor(originalIndex / chunkSize);
    // if (chunkIndex >= 0 && chunkIndex < arrayOfChunks.length) {
    //   const specificChunk = arrayOfChunks[chunkIndex];
    //   this.textToShow = specificChunk;
    // } else {
    //   console.log(`Index ${chunkIndex} is not available in the array.`);
    // }
  };

  playAudioDictation = (dictation: AudioDictationTableData): void => {
    if (dictation?.transcription_text) {
      this.transcriptionText = `
    NAME: <br>
    DOS: <br>
    MRN: <br>
    History Of Present Illness: <br><br>
    Physical Examination: <br><br>
    Order: <br><br>
    Assessment/Plan: <br><br>
    PLAN: <br>
  `;
    } else {
      this.transcriptionText = dictation.transcription_text;
    }

    const fileName = dictation.file_path;
    const parts = fileName.split('/');
    const fileNameWithExtension = parts[parts.length - 1];
    const lastDotIndex = fileNameWithExtension.lastIndexOf('.');
    const transcriptionName =
      fileNameWithExtension.substring(0, lastDotIndex) + '-transcription';

    this.spinner.show();
    this.transcriptionService
      .checkTranscriptionFileExistence(
        'vytlaudiobucket',
        `transcribed/${transcriptionName}.json`
      )
      .then((exists: boolean) => {
        if (exists) {
          this.transcriptionService
            .downloadFile(
              'vytlaudiobucket',
              `transcribed/${transcriptionName}.json`
            )
            .then((fileContent: ArrayBuffer) => {
              const bufferArray = new Uint8Array(fileContent);
              const decodedData = new TextDecoder('utf-8').decode(bufferArray);
              const jsonObject = JSON.parse(decodedData);
              this.transcription = jsonObject.results.items;
            })
            .catch((error: any) => {
              console.error('Error downloading transcription file:', error);
              this.transcription = [];
              this.textToShow = [];
            })
            .finally(() => this.spinner.hide());
        } else {
          this.toastr.info(
            'Transcription file not found. The transcription process might not be completed yet, please try again later.'
          );
          this.spinner.hide();
          this.transcription = [];
          this.textToShow = [];
        }
      })
      .catch((error: any) => {
        this.spinner.hide();
        console.error('Error checking transcription file existence:', error);
        this.transcription = [];
        this.textToShow = [];
      });
    if (dictation.id === this.selectedAudio.id) {
      this.audioPlayer.nativeElement.play();
    } else {
      this.selectedAudio = { url: dictation.file_path, id: dictation.id };
      (this.msaapPlaylist = {
        title: `${dictation.no} - ${dictation.name} | ${dictation['created_at']}`,
        link: dictation.file_path,
      }),
        this.audioPlayer.nativeElement.load();
      this.audioPlayer.nativeElement.play();
    }
  };

  pauseAudioDictation = (dictation: AudioDictationTableData): void => {
    if (dictation.id === this.selectedAudio.id) {
      this.audioPlayer.nativeElement.pause();
    }
  };

  searchRecords(event: KeyboardEvent, clearSearch?: boolean): void {
    if (clearSearch && event.code === 'Enter') this.searchKeyword = '';

    let order,
      provider = '';
    this.audioTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.selectedProvider && this.selectedProvider.id) {
      provider = this.selectedProvider.id;
    }
    this.getAudioDictations(
      this.tablePages.firstPage,
      this.pageSize,
      provider,
      order
    );
  }

  onChangeSwitch(page = 1) {
    this.getAudioDictations(page);
  }

  archivedFunction = (dictation: DictationData): void => {
    Swal.fire({
      title: 'Archive/Unarchive',
      text: 'Are you sure you want to proceed ?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed',
      confirmButtonColor: '#073786',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        this.audioDictationService
          .updateAudioDictation(
            dictation.id,
            this.attachmentSwitch.checked ? false : true
          )
          .then(() => {
            this.spinnerService.hide();
            this.getAudioDictations(this.tablePages.currentPage);
            this.toastr.success(
              this.attachmentSwitch.checked
                ? 'The dictation was unarchived.'
                : 'The dictation was archived.'
            );
          })
          .catch(() => {
            this.spinnerService.hide();
            this.toastr.error(
              this.attachmentSwitch.checked
                ? 'Failed to unarchive the dictation.'
                : 'Failed to archive the dictation.'
            );
          });
      }
    });
  };

  // acumenGetAudioDictations(): void {
  //   this.acumenFirebaseService.getData(`acumen-dictations`).then((snapshot: DataSnapshot) => {
  //     const data: AcumenDictationFirebaseData[] = snapshot.val();
  //     this.acumenDictationTableData = [];
  //     let index = 0;
  //     if (data) {
  //       Object.keys(data).forEach((elem: string) => {
  //         Object.keys(data[elem]).forEach((element) => {
  //           let res: AcumenDictationFirebaseData = data[elem][element];
  //           this.acumenDictationTableData.push({
  //             No: ++index,
  //             ParentKey: elem,
  //             Key: element,
  //             MRN: res.MRN,
  //             Date_Of_Service: res.dateOfService,
  //             File_Path: res.audioPath,
  //             Name: res.firstName + ' ' + res.lastName,
  //             Provider: res.renderingProvider,
  //           });
  //         });
  //       });
  //     }
  //   });
  // }

  getMissingAudioDictations(
    page: number = 1,
    limit: number = this.pageSize,
    filterByProvider: string = '',
    orderBy: any = {}
  ): void {
    this.spinnerService.show();

    this.audioDictationService
      .getMissingAudioDictations(
        this.assistantInfo.client_id,
        this.assistantInfo.practiceId,
        page,
        limit,
        this.searchKeyword,
        this.searchCriteria,
        filterByProvider,
        this.attachmentSwitch
          ? this.attachmentSwitch.checked
            ? true
            : false
          : null
      )
      .then((resp: GET_ALL_DICTATIONS_RESP) => {
        if (resp.data.total > 0) {
          this.recordFound = true;
        }
        this.missingAudioTablePages = {
          currentPage: resp.data.currentPage,
          totalPages: Math.trunc(
            (resp.data.total + resp.data.limit - 1) / resp.data.limit
          ),
          lastPage: Math.trunc(
            (resp.data.total + resp.data.limit - 1) / resp.data.limit
          ),
          nextPage: resp.data.nextPage,
          prevPage: resp.data.previousPage,
          firstPage: 1,
          total: resp.data.total,
        };
        this.MissingAudioDictationTableData = resp.data.data.map(
          (audio: any, index: number) => ({
            no: index + 1,
            id: audio.id,
            file_path: audio.file_url,
            name: audio.patient.patient_name,
            patient_id: audio.patient.patient_id,
            MRN: audio.patient.med_rec_nbr,
            provider: audio.provider.description,
            provider_id: audio.provider.provider_id,
            patient_row_id: audio.patient.patient_row_id,
            created_at:
              (audio.created_at &&
                moment(audio.created_at).format('MM/DD/YYYY hh:mm a')) ||
              '',
            status: audio.status,
            dictation_number: audio.dictation_number,
            transcription_text: audio.transcription_text,
          })
        );
      })
      .catch((err) => {
        this.toastr.error('Something went wrong. Please try again');
      })
      .finally(() => this.spinnerService.hide());
  }

  missingAudioFirstPage = (): void => {
    let order,
      provider = '';
    this.MissingAudioTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.selectedProvider && this.selectedProvider.id) {
      provider = this.selectedProvider.id;
    }
    if (this.missingAudioTablePages.prevPage)
      this.getMissingAudioDictations(
        this.missingAudioTablePages.firstPage,
        this.pageSize,
        provider,
        order
      );
  };

  missingAudioPrevPage = (): void => {
    let order,
      provider = '';
    this.MissingAudioTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.selectedProvider && this.selectedProvider.id) {
      provider = this.selectedProvider.id;
    }
    if (this.missingAudioTablePages.prevPage)
      this.getMissingAudioDictations(
        this.missingAudioTablePages.prevPage,
        this.pageSize,
        provider,
        order
      );
  };

  missingAudioNextPage = (): void => {
    let order,
      provider = '';
    this.MissingAudioTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.selectedProvider && this.selectedProvider.id) {
      provider = this.selectedProvider.id;
    }

    if (this.missingAudioTablePages.nextPage)
      this.getMissingAudioDictations(
        this.missingAudioTablePages.nextPage,
        this.pageSize,
        provider,
        order
      );
  };

  missingAudioLastPage = (): void => {
    let order,
      provider = '';
    this.MissingAudioTableHeaders.forEach((header) => {
      if (header.orderBy) {
        order = {
          column_name: header.value,
          sort: header.orderBy,
        };
      }
    });

    if (this.selectedProvider && this.selectedProvider.id) {
      provider = this.selectedProvider.id;
    }
    if (this.missingAudioTablePages.nextPage)
      this.getMissingAudioDictations(
        this.missingAudioTablePages.lastPage,
        this.pageSize,
        provider,
        order
      );
  };

  missingAudioCustomPage = (page: string): void => {
    const pageNumber = parseInt(page);
    if (pageNumber === this.missingAudioTablePages.currentPage) {
      this.toastr.info('Page already selected.');
      return;
    } else if (
      typeof pageNumber === 'number' &&
      pageNumber <= this.missingAudioTablePages.totalPages &&
      0 < pageNumber
    ) {
      let order,
        provider = '';
      this.MissingAudioTableHeaders.forEach((header) => {
        if (header.orderBy) {
          order = {
            column_name: header.value,
            sort: header.orderBy,
          };
        }
      });

      if (this.selectedProvider && this.selectedProvider.id) {
        provider = this.selectedProvider.id;
      }
      this.getMissingAudioDictations(
        pageNumber,
        this.pageSize,
        provider,
        order
      );
    } else
      this.toastr.error(
        'Please enter a page number between the range of ' +
          this.missingAudioTablePages.firstPage +
          ' to ' +
          this.missingAudioTablePages.lastPage
      );
  };

  sendMissingFilesNotifications = (): void => {
    Swal.fire({
      title: 'Send Notifications',
      text: 'This will send notifications to devices with missing files, are you sure you want to continue?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed',
      confirmButtonColor: '#073786',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        this.audioDictationService
          .sendMissingAudioDictationsNotifications(
            this.assistantInfo.client_id,
            this.assistantInfo.practiceId
          )
          .then((res) => {
            this.spinnerService.hide();
            this.toastr.success('Request has been processed successfully');
            this.onChangeSwitch(this.tablePages.currentPage);
          })
          .catch((err) => {
            this.spinnerService.hide();
            console.error(err);
            this.toastr.error(err.message);
          });
      }
    });
  };

  deleteUnsucessfulAudios = (): void => {
    Swal.fire({
      title: 'Clear Records',
      text: 'This will delete all unsuccessfull records, are you sure you want to continue?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed',
      confirmButtonColor: '#073786',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        this.audioDictationService
          .deleteUnsucessfulAudios(this.assistantInfo.practiceId)
          .then((res) => {
            this.spinnerService.hide();
            this.toastr.success('Successfully deleted');
            this.MissingAudioDictationTableData = [];
            this.recordFound = false;
            this.onChangeSwitch(this.tablePages.currentPage);
          })
          .catch((err) => {
            this.spinnerService.hide();
            console.error(err);
            this.toastr.error(err.message);
          });
      }
    });
  };
  // acumenDeleteAudioDictation = (dictation: AcumenDictationData): void => {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: "You won't be able to revert this!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, delete it!',
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       this.acumenFirebaseService
  //         .deleteAudioDictation(dictation.ParentKey, dictation.Key, dictation.File_Path)
  //         .then(() => {
  //           this.acumenGetAudioDictations();
  //         })
  //         .catch((err) => {
  //           console.error(err);
  //         });
  //     }
  //   });
  // };

  // acumenDownloadAudioDictation = (dictation: AcumenDictationData): void => {
  //   this.acumenFirebaseService.getFileUrl(dictation.File_Path).then((url: string) => {
  //     var xhr = new XMLHttpRequest();
  //     xhr.responseType = 'blob';
  //     xhr.onload = () => {
  //       var blob = new Blob([xhr.response], { type: 'audio/mp3' });
  //       var url = window.URL.createObjectURL(blob);
  //       const a = document.createElement('a');
  //       a.href = url;
  //       a.download = `${dictation.Name}${dictation['Date_/_Time']}` || 'download';
  //       const clickHandler = () => {
  //         setTimeout(() => {
  //           URL.revokeObjectURL(url);
  //           a.removeEventListener('click', clickHandler);
  //         }, 150);
  //       };
  //       a.addEventListener('click', clickHandler, false);
  //       a.click();
  //     };
  //     xhr.open('GET', url);
  //     xhr.send();
  //   });
  // };

  // acumenPlayAudioDictation = (dictation: AcumenDictationData): void => {
  //   if (dictation.Key === this.selectedAudio.id) {
  //     this.audioPlayer.nativeElement.play();
  //   } else {
  //     this.acumenFirebaseService.getFileUrl(dictation.File_Path).then((url: string) => {
  //       this.selectedAudio = { url, id: dictation.Key };
  //       (this.msaapPlaylist = {
  //         title: `${dictation.No} - ${dictation.Name}`,
  //         link: url,
  //       }),
  //         this.audioPlayer.nativeElement.load();
  //       this.audioPlayer.nativeElement.play();
  //     });
  //   }
  // };

  acumenPauseAudioDictation = (dictation: AcumenDictationData): void => {
    if (dictation.Key === this.selectedAudio.id) {
      this.audioPlayer.nativeElement.pause();
    }
  };

  openFilterModal(): void {
    this.spinnerService.show();
    this.appService
      .getProvidersHavingDictations(
        this.assistantInfo.client_id,
        this.assistantInfo.practiceId,
        'audio'
      )
      .then((resp) => {
        this.spinnerService.hide();
        const filterOptions = {
          filter: resp.data.map((n: Provider) => ({
            id: n.provider_id,
            title: n.description,
          })),
        };

        const filterModal = this.modalService.open(FilterModalComponent, {
          animation: true,
          centered: true,
        });

        filterModal.componentInstance.header = 'Providers';
        filterModal.componentInstance.filterOptions = filterOptions;
        if (this.selectedProvider)
          filterModal.componentInstance.selectedFilter =
            this.selectedProvider.description;

        filterModal.result
          .then((result) => {
            if (result) {
              this.audioTableHeaders.forEach((header) => {
                header.orderBy = '';
              });
              this.getAudioDictations(
                this.tablePages.firstPage,
                this.pageSize,
                result.id
              );
              this.selectedProvider = {
                description: result.title,
                id: result.id,
              };
            } else {
              this.audioTableHeaders.forEach((header) => {
                header.orderBy = '';
              });
              this.selectedProvider = {
                description: '',
                id: '',
              };
              this.getAudioDictations(this.tablePages.firstPage);
            }
          })
          .catch((err) => {
            if (err != 0) console.error(err);
          });
      })
      .catch(() => {
        this.spinnerService.hide();
      });
  }

  saveEdit() {
    if (!!this.selectedAudio.id) {
      this.spinner.show();
      this.audioDictationService
        .updateAudioTranscription(this.selectedAudio.id, this.transcriptionText)
        .then(
          (response) => {
            this.spinner.hide();
            this.toastr.success('Transcription updated successfully');
            this.transcriptionText = this.transcriptionText;
            const updatedDictationIndex =
              this.audioDictationTableData.findIndex(
                (dictation) => dictation.id === this.selectedAudio.id
              );
            if (updatedDictationIndex !== -1) {
              this.audioDictationTableData[
                updatedDictationIndex
              ].transcription_text = this.transcriptionText;
            }
          },
          (error) => {
            console.error('Error updating transcription:', error);
            this.toastr.error('Error updating transcription:', error);
          }
        );
    } else {
      this.toastr.error('Please select or play an audio first');
    }
  }

  downloadFile() {
    const processedContent = this.processHtmlContent(this.transcriptionText);
    const textContent = this.stripHtml(processedContent);
    if (!textContent.trim()) {
      this.toastr.error('No transcription text available to download.');
      return;
    }
    const blob = new Blob([textContent], {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });
    try {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'transcription.docx';
      link.type = 'application/octet-stream';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
      this.toastr.error('An error occurred while downloading the file.');
    }
  }

  processHtmlContent(html: string): string {
    html = html.replace(/&nbsp;/gi, ' ');
    html = html.replace(/<br\s*\/?>/gi, '\n');
    return html;
  }

  stripHtml(html: string): string {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  getTranscription = (event): void => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    event.target.value = '';
    formData.append('audio_type', '0');
    this.spinnerService.show();
    this.audioDictationService
      .getTranscription(formData)
      .then((res) => {
        this.spinnerService.hide();
        if (res.code === 200) {
          this.responseData = JSON.parse(res.message);
          this.transcriptionData = res?.data.transcription;
          const orderAddRef = this.modalService.open(
            TranscriptionModalComponent,
            {
              size: 'xl',
              animation: true,
            }
          );
          orderAddRef.componentInstance.responseData = this.responseData;
          orderAddRef.componentInstance.transcriptionData =
            this.transcriptionData;
        }
      })
      .catch((err) => {
        this.spinnerService.hide();
        console.error(err);
        this.toastr.error(err.message);
      });
  };
}
