<div class="modal-header">
  <h4 class="modal-title">AI Transcription</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div style="display: flex;">
<div [ngClass]="{'full-width': !transcriptionData, 'half-width': transcriptionData}">
  <div class="modal-body">
    <button class="btn btn-primary" (click)="copyAll()">Copy All</button>
  
    <h5>
      <button class="copy-btn" (click)="copySection('orders')">
        <i class="fas fa-thumbtack"></i>
      </button>
      Orders
    </h5>
    <p *ngIf="!parsedData?.Order?.length">NONE</p>
    <ul *ngIf="parsedData?.Order?.length" #orders>
      <li *ngFor="let order of parsedData.Order">
        <strong>Type:</strong> {{ order.type }} <br />
        <strong>Order:</strong> {{ order.order }} <br />
        <strong>Description:</strong> {{ order.description }}
      </li>
    </ul>
  
    <h5>
      <button class="copy-btn" (click)="copySection('doctorNotes')">
        <i class="fas fa-thumbtack"></i>
      </button>
      Doctor Notes
    </h5>
    <div *ngIf="parsedData?.doctor_notes" #doctorNotes>
      <p><strong>History of Present Illness:</strong></p>
      <p>{{ parsedData.doctor_notes['History Of Present Illness']}}</p>
  
      <p><strong>Physical Examination:</strong></p>
      <p>{{ parsedData.doctor_notes['Physical Examination'] }}</p>
  
      <p><strong>Diagnostics:</strong></p>
      <p>{{ parsedData.doctor_notes['Orders'] }}</p>
  
      <p><strong>Assessment/Plan:</strong></p>
      <p>{{ parsedData.doctor_notes['Assessment/Plan'] }}</p>
    </div>
  
    <h5>
      <button class="copy-btn" (click)="copySection('recommendations')">
        <i class="fas fa-thumbtack"></i>
      </button>
      Recommendations
    </h5>
    <div *ngIf="parsedData?.recommendations" #recommendations>
      <p *ngIf="parsedData?.recommendations?.tests?.length"><strong>Tests:</strong></p>
      <ul>
        <li *ngFor="let test of parsedData.recommendations.tests">
          {{ test.name }} - {{ test.reason }}
        </li>
      </ul>
  
      <p *ngIf="parsedData?.recommendations?.medications?.length"><strong>Medications:</strong></p>
      <ul>
        <li *ngFor="let med of parsedData.recommendations.medications">
          {{ med.name }} - {{ med.dosage }} ({{ med.purpose }})
        </li>
      </ul>
  
      <p *ngIf="parsedData?.recommendations?.treatments?.length"><strong>Treatments:</strong></p>
      <ul>
        <li *ngFor="let treatment of parsedData.recommendations.treatments">
          {{ treatment.name }} - {{ treatment.description }}
        </li>
      </ul>
  
      <p *ngIf="parsedData?.recommendations?.considerations?.length"><strong>Considerations:</strong></p>
      <p>{{ parsedData.recommendations.considerations }}</p>
    </div>
  </div>
</div>
<div *ngIf="transcriptionData" class="vr"></div>
<div [ngClass]="{'full-width': !transcriptionData, 'half-width': transcriptionData}" *ngIf="transcriptionData" style="margin-top: 20px;">
  <h5>
    <button class="copy-btn" (click)="copySection('doctorNotes')">
      <i class="fas fa-thumbtack"></i>
    </button>
    Transcription
  </h5>
  <div *ngIf="parsedData?.doctor_notes" #transcription>
    <p>{{ transcriptionData }}</p>
  </div>
</div>
</div>

<div class="modal-footer">
  <button class="btn btn-secondary" (click)="activeModal.close()">Close</button>
</div>